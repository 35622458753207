import { Box } from '@mui/material';
import useConsent from '@lib/hooks/useConsent';
import { useEffect } from 'react';
import { zIndices } from '@/theme';

export default function ProvenExpertBar() {
  const hasConsent = useConsent('provenexpert');

  useEffect(() => {
    if (!hasConsent) return;

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.provenexpert.com/widget/bar_csb-schimmel-automobile-gmbh.js?style=white&feedback=1';

    document.body.appendChild(script);

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeChild(script);
    };
  }, [hasConsent]);

  return (
    <Box
      id="ProvenExpert_widgetbar_container"
      sx={{
        display: { xs: 'none', md: 'block' },
        zIndex: zIndices.proventExpertBar,
      }}
    />
  );
}
