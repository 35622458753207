import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useContentElement } from '@plugins/next-cms-core';
import slugify from 'slugify';

export default function OnPageNavigationTargetBlock(props) {
  const { data } = props;
  const { elementData } = useContentElement(
    data,
    OnPageNavigationTargetBlock.dataSchema,
  );

  const { id, label, anchorName } = elementData;
  const anchorId = getAnchorRefName(id, anchorName);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '1px',
      }}
    >
      <a
        id={anchorId}
        style={{
          position: 'absolute',
          display: 'block',
          userSelect: 'none',
          top: -120,
          left: 0,
          height: '1px',
          width: '100%',
        }}
        title={label}
      />
    </Box>
  );
}

export function getAnchorRefName(id, name) {
  return name ? slugify(name) : `onpage-nav-index-${id}`;
}

OnPageNavigationTargetBlock.typeName = 'ComponentContentOnPageNavigationTarget'; // Strapi element type
OnPageNavigationTargetBlock.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    anchorName: PropTypes.string.isRequired,
  }).isRequired,
};

OnPageNavigationTargetBlock.graphQlSchema = `
... on ${OnPageNavigationTargetBlock.typeName} {
  id
  label
  anchorName
}
`;
