import { Fab, Fade, IconButton } from '@mui/material';
import { FiArrowUp, FiArrowUpCircle } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { zIndices } from '@/theme';

export default function ScrollToTopFab({ iconButton }) {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = throttle(() => {
    const {
      scrollY,
      innerHeight,
    } = window;
    const shouldBeVisible = scrollY > (innerHeight / 2);

    setVisible(shouldBeVisible);
  }, 100);
  const handleScrollToTop = () => {
    scrollToTop();
  };

  if (iconButton) {
    return (
      <IconButton
        aria-label="scroll to top"
        color="primary"
        onClick={handleScrollToTop}
        sx={{ display: 'block' }}
      >
        <Fade in={isVisible}>
          <div>
            <FiArrowUpCircle style={{ display: 'block' }} />
          </div>
        </Fade>
      </IconButton>
    );
  }

  return (
    <Fade in={isVisible}>
      <Fab
        aria-label="scroll to top"
        color="primary"
        onClick={handleScrollToTop}
        sx={{
          position: 'fixed',
          right: '30px',
          bottom: 80,
          zIndex: zIndices.scrollToTopFab,
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <FiArrowUp color="white" />
      </Fab>
    </Fade>
  );
}

ScrollToTopFab.propTypes = {
  iconButton: PropTypes.bool,
};

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}
