import { isClientSide } from '@lib/utils/environment';
import { loadKlaro } from '@lib/gdpr';

export default function useConsentManager() {
  return {
    changeConsent: async () => {
      if (isClientSide()) {
        const element = document.getElementById('klaro');

        if (element) element.style.display = 'block';
      }

      window.dispatchEvent(new CustomEvent('klaro:show'));
      const klaro = await loadKlaro();
      klaro.show(undefined, true);
    },
  };
}
