import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid2,
  Typography,
  useTheme,
  Skeleton,
} from '@mui/material';
import { getCarImages, getPrice } from '@plugins/vehiclefleetapp';
import {
  FiCalendar,
  FiCheck,
  FiClock,
} from 'react-icons/fi';
import {
  IoLocationOutline,
  IoSpeedometerOutline,
  IoCalendarOutline,
} from 'react-icons/io5';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { PiGasCanLight } from 'react-icons/pi';
import { getCarDetailPageRoute } from '@lib/routing';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import ModelName, { isNModel } from '@components/vehiclefleet/ModelName';
import ConsumptionEmissionsNotice, { GRAPHQL_REQUIRED_PROPS_FOR_CONSUMPTION } from '@components/vehiclefleet/ConsumptionEmissionsNotice';
import Image from '@components/atoms/Image2';
import CarBrandTitle from './CarBrandTitle';
import Link from '../atoms/Link';

export default function InventoryCarListItem(props) {
  const {
    item,
    isPurchasingEnabled,
    variant = 'default',
    useSkeleton,
  } = props;
  const theme = useTheme();
  const {
    t,
    i18n,
  } = useTranslation();
  const cardElevation = 3;

  if (useSkeleton) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minWidth: 0, // Important for truncating the header text
          '& *': {
            textDecoration: 'none !important',
          },
        }}
      >
        <Card elevation={cardElevation}>
          <CardHeader
            avatar={
              <Skeleton height={40} variant="circle" width={40} />
            }
            title={(
              <>
                <Skeleton />
                <Skeleton />
              </>
            )}
          />
          <Skeleton height={270} variant="rect" />
          <CardContent>
            <div style={{ height: 300 }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          </CardContent>
        </Card>
      </Box>
    );
  }

  const isHighlighted = isNModel(item.attributes.model);
  const images = getCarImages(item);

  const detailUrl = getCarDetailPageRoute(item.attributes.slug, i18n.language);
  const financingUrl = `${detailUrl}#show-financing-calculator`;
  const modelDetails = (item.attributes.model ?? '')
    .replace(item.attributes.baselineModel, '')
    .trim();

  const deliveryDate = item.attributes.deliveryDate
  && DateTime.fromISO(item.attributes.deliveryDate)
    .startOf('day') > DateTime.now()
    .startOf('day')
    ? DateTime.fromISO(item.attributes.deliveryDate)
      .toFormat('dd.MM.yyyy')
    : null;

  let carState = 'usedVehicle';
  const showPrice = !item.attributes.isDummyVehicle || item.attributes.price > 0;

  if (!item.attributes.initialRegistrationDate) {
    carState = 'newVehicle';
  } else if (item.attributes.isDemonstrationVehicle) {
    carState = 'demonstrationCar';
  } else if (item.attributes.isDailyAdmission) {
    carState = 'dailyAdmission';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minWidth: 0, // Important for truncating the header text
        '& *': {
          textDecoration: 'none !important',
        },
      }}
    >
      <Card
        elevation={cardElevation}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          ...(isHighlighted && ({
            borderStyle: 'solid',
            borderWidth: 3,
            borderLeftColor: '#FF241C',
            borderTopColor: '#FF241C',
            borderRightColor: '#252021',
            borderBottomColor: '#252021',
          })),
        }}
      >
        <Link href={detailUrl}>
          <CardActionArea
            sx={{
              flex: 1,
            }}
          >
            <CardHeader
              avatar={
                BRAND_LOGO_MAP[item.attributes.brand] ? (
                  <Image
                    alt={`${item.attributes.brand} Logo`}
                    height={38}
                    src={`/images/car-brands/${BRAND_LOGO_MAP[item.attributes.brand]}`}
                    styles={{
                      display: 'block',
                      width: 38,
                      height: 38,
                    }}
                    width={38}
                  />
                ) : null
              }
              disableTypography
              subheader={(
                <ModelName name={modelDetails} variant="body2" />
              )}
              sx={{
                '& > div': {
                  minWidth: 0,
                },
              }}
              title={(
                <Typography
                  component="div"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="h6"
                >
                  <CarBrandTitle value={item.attributes.brand} />
                  {' '}
                  {item.attributes.baselineModel}
                  {isHighlighted && (
                    <>
                      {' '}
                      <img
                        alt="N"
                        height={30}
                        src="/images/hyundai-n-logo.svg"
                        style={{
                          verticalAlign: 'middle',
                          marginTop: -6,
                        }}
                        width={48}
                      />
                    </>
                  )}
                  {item.attributes.model.includes('N-L') && ' N-Line'}
                </Typography>
              )}
            />
            <Image
              alt={`${item.attributes.brand} ${item.attributes.model}`}
              height={375}
              src={images[0]}
              style={{
                width: '100%',
                height: 'auto',
              }}
              width={500}
            />
            <CardContent>
              <Grid2 alignItems="center" container>
                {showPrice && (
                  <Grid2
                    size={12}
                    sx={{
                      marginLeft: item.attributes.leasingRate ? undefined : 'auto',
                    }}
                    width="auto"
                  >
                    {item.attributes.isSale && item.attributes.previousPrice && (
                      <Box display="flex">
                        <Typography
                          component="div"
                          sx={{
                            fontSize: '0.95rem',
                            textDecoration: 'none',
                            position: 'relative',
                            '&::before': {
                              top: '40%', /* tweak this to adjust the vertical position if it's off a bit due to your font family */
                              background: theme.palette.primary.main,
                              opacity: 0.70,
                              content: '""',
                              width: '120%',
                              position: 'absolute',
                              height: '.35em',
                              borderRadius: '.1em',
                              left: '-10%',
                              whiteSpace: 'nowrap',
                              display: 'block',
                              transform: 'rotate(-15deg)',
                            },
                          }}
                          variant="subtitle2"
                        >
                          <strong>{getPrice(item.attributes, false, 'previousPrice')}</strong>
                        </Typography>
                      </Box>
                    )}
                    <Typography component="div">
                      <Typography component="div" sx={{ lineHeight: '1' }} variant="h6">
                        <strong>
                          {getPrice(item.attributes)}
                        </strong>
                      </Typography>
                      <Typography component="div" variant="caption">
                        {t('components.vehiclefleet.InventoryCarListItem.vatIncluded')}
                      </Typography>
                    </Typography>
                  </Grid2>
                )}
                {item.attributes.leasingRate && (
                  <Grid2
                    sx={{
                      alignSelf: 'flex-start',
                      marginLeft: 'auto',
                    }}
                    width="auto"
                  >
                    <Typography component="div" sx={{ lineHeight: '1' }} variant="h6">
                      <Typography component="span" variant="caption">
                        {t('components.vehiclefleet.InventoryCarListItem.leasingRate')}
                        {' '}
                      </Typography>
                      <strong>
                        {item.attributes.leasingRate.toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 0,
                        })}
                        {' mtl.'}
                        <sup>II</sup>
                      </strong>
                    </Typography>
                  </Grid2>
                )}
              </Grid2>
              <Box
                my={1}
                sx={{
                  display: 'flex',
                }}
              >
                {item.attributes.isImmediatelyAvailable ? (
                  <Box>
                    <Link href={`${detailUrl}#show-contact-form`}>
                      <Chip
                        icon={<FiCheck />}
                        label={(
                          <span>
                            {t('components.vehiclefleet.InventoryCarListItem.availableImmediately')}
                          </span>
                        )}
                        size="small"
                        variant="outlined"
                      />
                    </Link>
                  </Box>
                ) : (
                  <Box>
                    <Link href={`${detailUrl}#show-contact-form`}>
                      <Chip
                        icon={<FiClock />}
                        label={(
                          <span>
                            {t('components.vehiclefleet.InventoryCarListItem.canBeOrderedImmediately')}
                          </span>
                        )}
                        size="small"
                        variant="outlined"
                      />
                    </Link>
                  </Box>
                )}
                <Box ml={1}>
                  <Chip
                    label={t(`components.vehiclefleet.InventoryCarListItem.vehicleTypes.${carState}`)}
                    size="small"
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box mb={2} mt={3}>
                <Grid2 container spacing={2}>
                  <Grid2 size={{ xs: 7 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IoLocationOutline />
                      <Typography component="div" ml={0.5} variant="body2">
                        {item.attributes.branch.data?.attributes.name}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 5 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IoSpeedometerOutline />
                      <Typography component="div" ml={0.5} variant="body2">
                        {item.attributes.mileage.toLocaleString('de-DE')}
                        {' km'}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 7 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <PiGasCanLight />
                      <Typography component="div" ml={0.5} variant="body2">
                        {t(`components.vehiclefleet.InventoryCarListItem.fuelTypes.${item.attributes.isPluginHybrid ? 'plugInHybrid' : item.attributes.fuelType}`)}
                      </Typography>
                    </Box>
                  </Grid2>
                  {item.attributes.initialRegistrationDate && (
                    <Grid2 size={{ xs: 5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <IoCalendarOutline />
                        <Typography component="div" ml={0.5} variant="body2">
                          {`EZ ${DateTime
                            .fromISO(item.attributes.initialRegistrationDate)
                            .toFormat('MM-yyyy')}`}
                        </Typography>
                      </Box>
                    </Grid2>
                  )}
                </Grid2>
              </Box>
              {deliveryDate && (
                <Box my={1}>
                  <Chip
                    color="default"
                    icon={<FiCalendar />}
                    label={(
                      <span>
                        {t('components.vehiclefleet.InventoryCarListItem.estimatedDeliveryDate')}
                        {' '}
                        <strong>
                          {deliveryDate}
                        </strong>
                      </span>
                    )}
                    size="small"
                  />
                </Box>
              )}
              <Box>
                <ConsumptionEmissionsNotice
                  car={item}
                  variant="compact"
                />
              </Box>
            </CardContent>
          </CardActionArea>
        </Link>
        {variant === 'default' && (
          <CardActions
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            {item.attributes.isCarSubscription && (
              <Link href={detailUrl} legacyBehavior>
                <Button
                  color="primary"
                  component="a"
                  startIcon={<IoMdCheckboxOutline />}
                >
                  {t('components.vehiclefleet.InventoryCarListItem.subscription')}
                </Button>
              </Link>
            )}
            {!item.attributes.isPurchased && (
              <Link href={financingUrl} legacyBehavior>
                <Button
                  color="primary"
                  component="a"
                  startIcon={<IoMdCheckboxOutline />}
                >
                  {t('components.vehiclefleet.InventoryCarListItem.finance')}
                </Button>
              </Link>
            )}
            <Link href={detailUrl} legacyBehavior>
              <Button
                color="primary"
                component="a"
                startIcon={<IoMdCheckboxOutline />}
              >
                {t('components.vehiclefleet.InventoryCarListItem.lease')}
              </Button>
            </Link>
          </CardActions>
        )}
      </Card>
    </Box>
  );
}
InventoryCarListItem.propTypes = {
  item: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    price: PropTypes.number,
  }),
  variant: PropTypes.oneOf([
    'default',
    'checkout',
  ]),
  useSkeleton: PropTypes.bool,
  isPurchasingEnabled: PropTypes.bool,
};

export const BRAND_LOGO_MAP = {
  HYUNDAI: 'hyundai-motor-company-logo-square.svg',
  MITSUBISHI: 'mitsubishi-logo.svg',
  FUSO: 'mitsubishi-fuso-logo-square.svg',
  MG: 'mgmotors-logo-square.svg',
  MAXUS: 'maxus-logo.png',
};

export const GRAPHQL_REQUIRED_PROPS = `
  id
  attributes {
    internalId
    isDeleted
    isPurchased
    slug
    branch {
      data {
        id
        attributes {
          branchId
          name
        }
      }
    }
    category
    brand
    baselineModel
    model
    power
    horsePower
    isNewCar
    isPluginHybrid
    isPluginHybrid
    generalInspectionDate
    initialRegistrationDate
    mileage
    price
    previousPrice
    originalPrice
    currency
    isVatAvailable
    isOldtimer
    isSale
    isDummyVehicle
    vehicleIdentificationNumber
    color
    isPurchased
    hsn
    tsn
    fuelType
    transmissionType
    pollutantClass
    deliveryDate
    isImmediatelyAvailable
    isDemonstrationVehicle
    isDailyAdmission
    carAttributes
    leasingRate
    isCarSubscription
    ${GRAPHQL_REQUIRED_PROPS_FOR_CONSUMPTION}
  }
`;
