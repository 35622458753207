// eslint-disable-next-line max-classes-per-file
import { isServerSide } from '@lib/utils/environment';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { gdprConfig } from '@/constants';

class Watcher {
  constructor(handler) {
    this.handler = handler;
  }

  update(obj, name, data) {
    this.handler(obj, name, data);
  }
}

class KlaroServer {
  // eslint-disable-next-line class-methods-use-this
  getManager() {
    return {
      consents: {},
      watch: () => { },
      unwatch: () => { },
    };
  }

  render() {
    return null;
  }
}

export async function getConsentAsync(name) {
  const klaro = await loadKlaro();
  const consentManager = klaro.getManager();
  return consentManager.consents[name];
}

export async function addListenerAsync(handler) {
  const klaro = await loadKlaro();
  const consentManager = klaro.getManager();
  const watcher = new Watcher(handler);
  consentManager.watch(watcher);

  return () => {
    consentManager.unwatch(watcher);
  };
}

export function useHideConsentOnPrivacyPage(paths = []) {
  const router = useRouter();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const match = Boolean(paths.find((p) => router.asPath.startsWith(p)));
      const element = document.getElementById('klaro');

      if (!element) return;

      element.style.display = match ? 'none' : 'block';
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [paths, router.asPath]);
}

export function hasConsentDecided() {
  return Boolean(
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('klaro='))
      ?.split('=')[1],
  );
}

export async function loadKlaro() {
  if (isServerSide()) {
    return Promise.resolve(new KlaroServer());
  }

  if (window.klaro) {
    return Promise.resolve(window.klaro);
  }

  console.log('[GDPR] Loading dependency.');

  const i18n = await import('i18next');
  i18n.default.on('languageChanged', () => {
    if (window.klaroConfig) {
      window.klaroConfig.lang = i18n.default.language;
      window.klaro.setup(window.klaroConfig);
    }
  });

  await import('klaro/dist/klaro.css');
  return import('klaro').then((klaro) => {
    window.klaro = klaro;
    window.klaroConfig = gdprConfig;
    klaro.setup(gdprConfig);
    return klaro;
  });
}
