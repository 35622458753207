import { List } from '@mui/material';
import PropTypes from 'prop-types';
import LanguagePopover from '@components/organisms/LanguagePopover';
import { useState, forwardRef } from 'react';
import { navigationItemShape } from './propTypes';
import MobileMenuItem from './MobileMenuItem';

function MobileMenu(props, ref) {
  const {
    items,
    onClick,
    locales,
    pageUrls,
  } = props;
  const [openPopover, setOpenPopover] = useState(false);

  const handleClosePopover = () => {
    setOpenPopover(false);
    onClick();
  };
  const handleOpen = () => {
    setOpenPopover(true);
  };

  return (
    <List ref={ref} component="nav">
      <LanguagePopover
        handleOpen={handleOpen}
        locales={locales ?? []}
        onClose={handleClosePopover}
        open={openPopover}
        pageUrls={pageUrls}
      />
      {items.map((item) => (
        <MobileMenuItem
          key={item.path}
          item={item}
          level={1}
          onClick={onClick}
        />
      ))}
    </List>
  );
}

const MobileMenuWithRef = forwardRef(MobileMenu);

MobileMenuWithRef.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};

export default MobileMenuWithRef;
