import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import useConsent from '@lib/hooks/useConsent';
import { useContentElement } from '@plugins/next-cms-core';
import Container from '../atoms/Container';
import ConsentRequiredFallback from '../molecules/ConsentRequiredFallback';

export default function SoftgardenBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const hasConsent = useConsent('softgarden');

  // eslint-disable-next-line no-unused-vars
  useContentElement(
    data,
    SoftgardenBlock.dataSchema,
  );

  if (!hasConsent) {
    return <ConsentRequiredFallback />;
  }

  return (
    <Box
      sx={{
        py: theme.spacing(3),
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <iframe
          height="108"
          src="https://certificate.softgarden.io/widget/schimmel-automobile/de"
          style={{
            border: 'none',
          }}
          title="Softgarden"
          width="256"
        />
      </Container>
    </Box>
  );
}

SoftgardenBlock.typeName = 'ComponentContentSoftgardenRatings'; // Strapi element type
SoftgardenBlock.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
};

SoftgardenBlock.graphQlSchema = `
... on ${SoftgardenBlock.typeName} {
  id
}
`;
