import { useEffect, useState } from 'react';
import { isServerSide } from '@lib/utils/environment';
import { addListenerAsync, getConsentAsync, hasConsentDecided } from '@lib/gdpr';

export default function useConsent(serviceName) {
  const [hasConsent, setConsent] = useState(null);

  useEffect(() => {
    if (isServerSide()) {
      setConsent(false);
      return () => {};
    }

    let interval = null;
    let listener = () => { };
    const handleCookiesChange = () => {
      if (!hasConsentDecided()) {
        return;
      }

      clearInterval(interval);
      addListenerAsync((obj, name, data) => {
        if (name !== 'applyConsents') {
          return;
        }

        getConsentAsync(serviceName).then((consent) => {
          setConsent(consent);
        });
      }).then((remove) => {
        listener = remove;

        getConsentAsync(serviceName).then((consent) => {
          setConsent(consent);
        });
      });
    };

    interval = setInterval(handleCookiesChange, 1000);
    handleCookiesChange();

    return () => {
      clearInterval(interval);
      listener();
    };
  }, [setConsent, serviceName]);

  return hasConsent;
}
