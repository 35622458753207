import * as yup from 'yup';

/**
 * Car search
 */
const CAR_SEARCH_ROUTE_ROOT = {
  de: '/fahrzeugsuche',
  en: '/car-search',
  es: '/buscar-coche',
  ru: '/poisk-avtomobilya',
  tr: '/araba-arama',
};

export function getCarSearchRoute(locale, params = null) {
  return `${CAR_SEARCH_ROUTE_ROOT[locale]}${params ? `?${params}` : ''}`;
}

export function getCarDetailPageRoute(slug, locale) {
  return `${CAR_SEARCH_ROUTE_ROOT[locale]}/${slug}`;
}

export function getCarCheckoutRoute(inventoryCarItem, locale) {
  yup.object().shape({
    internalId: yup.string().required(),
  }).validateSync(inventoryCarItem);

  return `${CAR_SEARCH_ROUTE_ROOT[locale]}/checkout/${inventoryCarItem.internalId}`;
}

/**
 * Blog
 */
const BLOG_ROUTE_ROOT = '/blog';

export function getBlogRoute() {
  return BLOG_ROUTE_ROOT;
}

/**
 * Car parts shop
 */
const CAR_PARTS_SHOP_ROUTE = {
  de: '/teileshop',
  en: '/parts-shop',
  es: '/tienda-de-recambios',
  ru: '/magazin-zapchastej',
  tr: '/parca-atoelyesi',
};

export function getCarPartsShopRoute({ dealerid, series, locale } = {}) {
  const urlParts = [CAR_PARTS_SHOP_ROUTE[locale]];

  if (dealerid) {
    urlParts.push(dealerid);
  }

  if (series) {
    urlParts.push(series);
  }

  return urlParts.join('/');
}
