import { styled } from '@mui/material/styles';
import {
  Button, Paper, Typography,
} from '@mui/material';
import BrandLogo from '@components/molecules/BrandLogo';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadKlaro, hasConsentDecided } from '@lib/gdpr';
import { zIndices } from '@/theme';

export default function GdprConsent() {
  const [hideDialog, setHideDialog] = useState(false);

  useEffect(() => {
    const listener = () => {
      setHideDialog(true);
    };

    setHideDialog(hasConsentDecided());

    window.addEventListener('klaro:show', listener);

    return () => {
      window.removeEventListener('klaro:show', listener);
    };
  }, []);

  const handleAcceptAll = useCallback(async () => {
    setHideDialog(true);
    const klaro = await loadKlaro();
    const consentManager = klaro.getManager();
    consentManager.changeAll(true);
    consentManager.saveAndApplyConsents();
  }, []);
  const handleDecline = useCallback(async () => {
    setHideDialog(true);
    const klaro = await loadKlaro();
    const consentManager = klaro.getManager();
    consentManager.changeAll();
    consentManager.saveAndApplyConsents();
  }, []);
  const handleDecide = useCallback(async () => {
    setHideDialog(true);
    const klaro = await loadKlaro();
    klaro.show(undefined, true);
  }, []);

  if (hideDialog) {
    return null;
  }

  return (
    <ConsentDialog
      onAcceptAll={handleAcceptAll}
      onDecide={handleDecide}
      onDecline={handleDecline}
    />
  );
}

const StyledConsentDialog = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  right: theme.spacing(3),
  bottom: theme.spacing(3),
  zIndex: zIndices.gdprConsent,
  width: `calc(100% - ${theme.spacing(3 * 2)})`,
  height: '100%',
  maxHeight: 400,
  background: theme.palette.dark.main,
  color: theme.palette.dark.contrastText,
  [theme.breakpoints.up('sm')]: {
    maxWidth: 400,
  },
  '& > .content': {
    flex: 1,
    overflow: 'auto',
    marginTop: theme.spacing(2),
  },
  '& > button': {
    marginTop: theme.spacing(1),
  },
}));

function ConsentDialog(props) {
  const {
    onAcceptAll,
    onDecline,
    onDecide,
  } = props;
  const { t } = useTranslation();

  return (
    <StyledConsentDialog elevation={5}>
      <BrandLogo
        alt="CSB Schimmel Automobile"
        color="inverted"
        style={{
          width: 'auto',
          height: 40,
        }}
      />
      <Typography
        className="content"
        component="div"
        dangerouslySetInnerHTML={{
          __html: t('components.organisms.ConsentDialog.content.description'),
        }}
        variant="body2"
      />
      <Button
        fullWidth
        onClick={onDecide}
        size="small"
        variant="text"
      >
        {t('components.organisms.ConsentDialog.actions.decide')}
      </Button>
      <Button
        color="secondary"
        fullWidth
        onClick={onDecline}
        variant="outlined"
      >
        {t('components.organisms.ConsentDialog.actions.decline')}
      </Button>
      <Button
        color="primary"
        fullWidth
        onClick={onAcceptAll}
        variant="contained"
      >
        {t('components.organisms.ConsentDialog.actions.acceptAll')}
      </Button>
    </StyledConsentDialog>
  );
}
