/* eslint-disable react/no-array-index-key */
import {
  Box, Grid2, Paper, Typography, useTheme,
} from '@mui/material';
import {
  ButtonElement, MediaElement, TypographyElement, useContentElement,
} from '@plugins/next-cms-core';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { useState } from 'react';
import Slider from '@components/atoms/Slider';
import Container from '../atoms/Container';

export default function TextMediaBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    TextMediaBlock.dataSchema,
  );

  const variant = elementData.variant ?? 'Plain';
  const order = elementData.order ?? 'TextMedia';

  const items = [];
  const imageElement = {
    sx: { zIndex: 0 },
    className: 'mediaContainer',
    element: <ImageBlock data={elementData} theme={theme} variant={variant} />,
  };
  const textElement = {
    sx: { zIndex: 1 },
    className: 'textContainer',
    element: <TextBlock data={elementData} variant={variant} />,
  };

  switch (order) {
    case 'MediaText':
      items.push(imageElement);
      items.push(textElement);
      break;
    default:
      items.push(textElement);
      items.push(imageElement);
      break;
  }

  const columnSpan = variant === 'Card' ? 6 : 6;
  const columnSpacing = variant === 'Card' ? 0 : 10;

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        ...(order === 'MediaText' ? ({
          '& .mediaContainer': {
            transformOrigin: 'left center',
          },
          '& .mediaLabel': {
            left: theme.shape.borderRadius,
          },
        }) : ({
          '& .mediaContainer': {
            transformOrigin: 'right center',
          },
          '& .mediaLabel': {},
        })),
        ...(variant === 'Card' && ({
          marginTop: theme.spacing(10),
          marginBottom: theme.spacing(10),
          '& .mediaContainer': {
            transform: 'scale(1.2)',
          },
          '& .mediaLabel': {
            maxWidth: '70%',
          },
        })),
      }}
    >
      <Container>
        <Grid2
          alignItems="center"
          container
          spacing={columnSpacing}
          sx={{ my: 10 }}
        >
          {items.map((item, index) => (
            <Grid2
              key={index}
              className={item.className}
              size={{ xs: 12, md: columnSpan }}
              sx={item.sx}
            >
              {item.element}
            </Grid2>
          ))}
        </Grid2>
      </Container>
    </Box>
  );
}

function ImageBlock(props) {
  const {
    data, theme,
  } = props;
  const {
    media,
  } = data;
  const [swiper, setSwiper] = useState();

  if (media.length === 0) {
    return null;
  }

  if (media.length === 1) {
    const firstMedia = media[0];

    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <MediaElement
          data={firstMedia}
          format="medium"
        />
        {firstMedia.caption && (
          <Box
            className="mediaLabel"
            sx={{
              flex: 1,
              marginTop: theme.spacing(0.5),
              // position: 'absolute',
              // eslint-disable-next-line max-len
              // bottom: `calc(${(theme.typography.caption.lineHeight) * -1}em - ${theme.spacing(0.5)}px)`,
            }}
          >
            <Typography variant="caption">
              {firstMedia.caption}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Slider
      autoHeight
      autoplay={{
        delay: 5000,
      }}
      isButtonsVisible={false}
      items={media}
      setSwiper={setSwiper}
      SlideComponent={TextMediaSlideItem}
      slideComponentProps={{
        theme,
      }}
      sliderStyles={{
        paddingBottom: theme.spacing(3),
        marginTop: -7,
        paddingTop: 20,
      }}
      swiper={swiper}
    />
  );
}

function TextMediaSlideItem(props) {
  const { theme, item } = props;
  return (
    <>
      <MediaElement data={item} />
      <Box
        className="mediaLabel"
        sx={{
          flex: 1,
          marginTop: theme.spacing(0.5),
          // position: 'absolute',
          // eslint-disable-next-line max-len
          // bottom: `calc(${(theme.typography.caption.lineHeight) * -1}em - ${theme.spacing(0.5)}px)`,
        }}
      >
        <Typography variant="caption">
          {item.caption}
        </Typography>
      </Box>
    </>
  );
}

function TextBlock(props) {
  const { variant, data } = props;
  const { buttons } = data;

  const title = omitBy(data.textMediaTitle, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const subtitle = omitBy(data.textMediaSubtitle, isNull);
  defaults(subtitle, {
    semanticVariant: 'div',
    displayVariant: 'body2',
  });

  const text = omitBy(data.textMediaText, isNull);
  defaults(text, {
    semanticVariant: 'div',
    displayVariant: 'body1',
  });

  const content = (
    <>
      {subtitle?.value && (
        <Box mb={2}>
          <TypographyElement
            color="primary"
            data={subtitle}
            sx={{
              '& p': {
                margin: 0,
              },
            }}
          />
        </Box>
      )}
      {title?.value && (
        <Box mb={3}>
          <TypographyElement
            data={title}
            sx={{
              '& p': {
                marginTop: 0,
              },
            }}
          />
        </Box>
      )}
      {text?.value && (
        <Box>
          <TypographyElement data={text} />
          {buttons && (
            <Box mt={5}>
              <Grid2 container spacing={2}>
                {buttons.map((button) => (
                  <Grid2 key={button.id}>
                    <ButtonElement
                      color="primary"
                      component="a"
                      data={button}
                      variant="contained"
                    />
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          )}
        </Box>
      )}
    </>
  );

  if (variant === 'Card') {
    return (
      <Paper elevation={3}>
        <Box p={5}>
          {content}
        </Box>
      </Paper>
    );
  }

  return content;
}

const PROPTYPE_VARIANT = PropTypes.oneOf(['Plain', 'Card']);

TextMediaBlock.typeName = 'ComponentContentTextMedia'; // Strapi element type
TextMediaBlock.propTypes = {
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    order: PropTypes.oneOf(['TextMedia', 'MediaText']),
    media: PropTypes.arrayOf(PropTypes.shape(MediaElement.propTypes)),
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonElement.propTypes)),
  }).isRequired,
};

TextMediaBlock.graphQlSchema = `
... on ${TextMediaBlock.typeName} {
  id
  variant
  order
  media {
    ${MediaElement.graphQlSchema}
  }
  textMediaTitle: title {
    ${TypographyElement.graphQlSchema}
  }
  textMediaSubtitle: subtitle {
    ${TypographyElement.graphQlSchema}
  }
  textMediaText: text {
    ${TypographyElement.graphQlSchema}
  }
  buttons {
    ${ButtonElement.graphQlSchema}
  }
}
`;

TextBlock.propTypes = {
  data: PropTypes.shape({
    variant: PROPTYPE_VARIANT,
    title: TypographyElement.propTypes,
    subtitle: TypographyElement.propTypes,
    text: TypographyElement.propTypes,
  }).isRequired,
};

ImageBlock.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.oneOf(['image', 'video']),
    }),
  }).isRequired,
};
