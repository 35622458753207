import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { navigationItemShape } from './propTypes';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

function MainMenu(props, ref) {
  const {
    desktop,
    items,
    locales,
    mobile,
    onClick,
    pageUrls,
    sx,
  } = props;
  const menuItems = items.filter((item) => item.path !== '/' && item.props?.hidden !== 'all');
  const desktopItems = menuItems.filter((m) => m.props?.hidden !== 'desktop');
  const mobileItems = menuItems.filter((m) => m.props?.hidden !== 'mobile');

  const handleItemClick = (e) => {
    if (onClick) onClick(e);
  };

  return desktop ? (
    <DesktopMenu
      ref={ref}
      items={desktopItems}
      onClick={handleItemClick}
      sx={sx}
    />
  ) : (
    <MobileMenu
      ref={ref}
      items={mobileItems}
      locales={locales}
      onClick={handleItemClick}
      pageUrls={pageUrls}
      sx={sx}
    />
  );
}

const MainMenuWithRef = forwardRef(MainMenu);

MainMenuWithRef.propTypes = {
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};

export default MainMenuWithRef;
