import { ButtonElement, TypographyElement } from '@plugins/next-cms-core';
import {
  Box,
  Button,
  Collapse,
  Typography,
} from '@mui/material';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ReadMoreContainer({
  data,
  expandedPanel,
  itemIndex,
  suffix,
}) {
  const [isExpanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  let value = data.text?.value;
  let readMoreValue = null;

  if (data?.value?.includes('- - -')) {
    const parts = data.value.split('- - -');
    const [partsValue, partsReadMoreValue] = parts;
    value = partsValue;
    readMoreValue = partsReadMoreValue;
  }
  return (
    <>
      <TypographyElement
        data={{
          ...data.text,
          value,
        }}
      />
      {readMoreValue && (
        <>
          <Box mt={1}>
            <Button
              onClick={() => setExpanded(!isExpanded)}
              size="small"
              startIcon={isExpanded ? <FiChevronUp /> : <FiChevronDown />}
            >
              {t('components.atoms.ReadMoreContainer.readMore')}
            </Button>
          </Box>
          <Collapse in={isExpanded}>
            <Box mt={2}>
              <TypographyElement
                data={{
                  ...data,
                  value: readMoreValue,
                }}
              />
            </Box>
          </Collapse>
        </>
      )}
      {(suffix || data.buttons.length > 0) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 'auto',
          }}
          >
            {data.buttons.map((button) => (
              <ButtonElement
                key={button.id}
                component="a"
                data={button}
                fullWidth
                size="small"
                sx={{
                  flexBasis: 'content',
                  mr: 1,
                }}
                variant="outlined"
              />
            ))}
          </Box>
          {suffix && expandedPanel === itemIndex && (
            <Typography component="h3" variant="subtitle2">
              {suffix}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}

ReadMoreContainer.propTypes = {
  data: PropTypes.object.isRequired,
};
