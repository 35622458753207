import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import LinkElement from './LinkElement';
import useContentElement from '../../hooks/useContentElement';

export default function ButtonElement(props) {
  const { data, ...otherProps } = props;
  const { elementData } = useContentElement(
    data,
    ButtonElement.dataSchema,
  );

  // Set default values
  const { link } = elementData;
  const label = elementData.label ?? '';
  const color = elementData.color ?? otherProps.color ?? null;

  return (
    <LinkElement data={link} onClick={otherProps.onClick}>
      <Button
        color={color}
        component="a"
        {...otherProps}
      >
        {label}
      </Button>
    </LinkElement>
  );
}

ButtonElement.typeName = 'ButtonElement'; // Strapi element type
ButtonElement.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    link: LinkElement.propTypes.isRequired,
  }).isRequired,
};

ButtonElement.graphQlSchema = `
  id
  label
  color
  link {
    ${LinkElement.graphQlSchema}
  }
`;
