import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledMenuChevronDownIcon = styled(Box)(({ theme }) => ({
  '--s': '2px', /* control the shape (can be percentage) */
  width: '10px',
  marginTop: '2px',
  aspectRatio: '5/3',
  clipPath: 'polygon(0 0,0 var(--s),50% 100%,100% var(--s),100% 0,50% calc(100% - var(--s)))',
  background: theme.palette.text.primary,
  opacity: 0.5,
}));

export default StyledMenuChevronDownIcon;
