import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import useConsent from '@lib/hooks/useConsent';
import Container from '../atoms/Container';
import ConsentRequiredFallback from '../molecules/ConsentRequiredFallback';

export default function SoftgardenJobsBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const hasConsent = useConsent('softgarden');

  // eslint-disable-next-line no-unused-vars
  const { elementData } = useContentElement(
    data,
    SoftgardenJobsBlock.dataSchema,
  );

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <Box
      sx={{
        py: theme.spacing(3),
      }}
    >
      <Container>
        {title && (
          <Box mb={3}>
            <TypographyElement data={title} />
          </Box>
        )}
        {!hasConsent ? (
          <ConsentRequiredFallback />
        ) : (
          <iframe
            src="https://schimmel-automobile.softgarden.io/de/widgets/jobs"
            style={{
              width: '100%',
              height: 1500,
              overflow: 'hidden',
              border: 'none',
            }}
            title="Softgarden"
          />
        )}
      </Container>
    </Box>
  );
}

SoftgardenJobsBlock.typeName = 'ComponentContentSoftgardenJobs'; // Strapi element type
SoftgardenJobsBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propType,
  }).isRequired,
};

SoftgardenJobsBlock.graphQlSchema = `
... on ${SoftgardenJobsBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
}
`;
