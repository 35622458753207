/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';

export function ContentBlockRenderer(props) {
  const {
    appData,
    data,
    context,
    components,
    preComponentRender,
  } = props;
  const {
    id,
    blocks,
  } = data;

  return blocks.map((blockItem) => {
    const Component = components
      .find((component) => component.typeName === blockItem.__typename);

    if (!Component) {
      throw new Error(`Component of type '${blockItem.__typename}' not found.\nRegister component in "cmsconfig.js" file.`);
    }

    return (
      <>
        {preComponentRender && preComponentRender(Component)}
        <Component
          key={`${id}_${blockItem.__typename}_${blockItem.id}`}
          appData={appData}
          blocks={blocks}
          context={context}
          data={blockItem}
        />
      </>
    );
  });
}

ContentBlockRenderer.propTypes = {
  data: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.any.isRequired,
      __typename: PropTypes.string.isRequired,
    })),
  }).isRequired,
  context: PropTypes.shape({
    page: PropTypes.object.isRequired,
  }).isRequired,
};
