import { List, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, forwardRef } from 'react';
import { navigationItemShape } from './propTypes';
import Container from '../../atoms/Container';
import DesktopMenuItem from './DesktopMenuItem';

function DesktopMenu(props, ref) {
  const {
    items,
    onClick,
  } = props;
  const listRef = useRef();
  const theme = useTheme();

  return (
    <Container ref={ref}>
      <List
        ref={listRef}
        component="nav"
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          color: theme.palette.secondary.contrastText,
        }}
      >
        {items.map((item) => (
          <DesktopMenuItem
            key={item.path}
            item={item}
            level={1}
            menuRef={listRef}
            onClick={onClick}
          />
        ))}
      </List>
    </Container>
  );
}

const DesktopMenuWithRef = forwardRef(DesktopMenu);

DesktopMenuWithRef.propTypes = {
  items: PropTypes.arrayOf(navigationItemShape),
  // Events
  onClick: PropTypes.func,
};

export default DesktopMenuWithRef;
