import {
  Collapse,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { FiTag } from 'react-icons/fi';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import Image from '@components/atoms/Image2';
import MenuChevronDownIcon from '@components/atoms/MenuChevronDownIcon';
import Link from '../../atoms/Link';
import MenuItemBadge from './MenuItemBadge';
import { navigationItemShape } from './propTypes';

const COLLAPSE_ITEMS_BELOW_LEVEL = 1;

export default function MobileMenuItem(props) {
  const {
    item,
    level,
    onClick,
  } = props;
  const theme = useTheme();
  const [isExpanded, setExpanded] = useState(false);
  const hasItems = item.items?.length > 0;
  const markedItems = [74, 75, 76, 77];

  const handleToggleItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setExpanded(!isExpanded);
  };

  return (
    <>
      <Link href={item.path} legacyBehavior>
        <ListItem
          button
          component="a"
          onClick={onClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            ...(item.highlighting === 'promotion' && ({
              fontWeight: '900',
              color: theme.palette.primary.main,
            })),
            fontWeight: level === 1 ? '700' : 'inherit',
            ...(item.highlighting === 'bold' && ({
              fontWeight: '900',
            })),
          }}
        >
          <Typography component="span" sx={{ mr: 'auto' }}>
            {trim(item.title)}
          </Typography>
          {markedItems.includes(item.id) && (
          <>
            <Image
              alt="Hyundai"
              height={618}
              src="/images/car-brands/hyundai-motor-company-logo-2.svg"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: theme.spacing(1),
                width: 24,
                height: 24,
              }}
              width={618}
            />
            <Image
              alt="MG Motor"
              height={500}
              src="/images/car-brands/mgmotors-logo.svg"
              style={{
                display: 'block',
                marginRight: theme.spacing(1),
                width: 15,
                height: 15,
              }}
              width={500}
            />
            <Image
              alt="Mitsubishi"
              height={172}
              src="/images/car-brands/mitsubishi-logo.svg"
              style={{
                display: 'block',
                marginRight: theme.spacing(1),
                width: 15,
                height: 15,
              }}
              width={172}
            />
          </>
          )}
          {item.props?.badge && (
            <MenuItemBadge
              // No icon for performance reasons
              // icon={item.props.badgeIcon}
              label={item.props.badge}
            />
          )}
          {hasItems && (
            <IconButton
              onClick={handleToggleItem}
              size="large"
            >
              <MenuChevronDownIcon
                sx={{
                  transition: theme.transitions.create('all', {
                    duration: theme.transitions.duration.complex,
                  }),
                  ...(isExpanded && ({
                    transform: 'rotate(180deg)',
                  })),
                }}
              />
            </IconButton>
          )}
          {item.highlighting === 'promotion' && (
            <IconButton>
              <FiTag
                style={{
                  transition: theme.transitions.create('all', {
                    duration: theme.transitions.duration.complex,
                  }),
                  fontWeight: '900',
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
          )}
        </ListItem>
      </Link>
      {hasItems && (
        <Collapse
          in={isExpanded}
          mountOnEnter={level > COLLAPSE_ITEMS_BELOW_LEVEL}
          timeout="auto"
          unmountOnExit={level > COLLAPSE_ITEMS_BELOW_LEVEL}
        >
          <List
            component="div"
            disablePadding
            sx={{
              mb: 3,
              pl: 4,
            }}
          >
            {item.items.map((subItem) => (
              <MobileMenuItem
                key={subItem.path}
                item={subItem}
                level={level + 1}
                onClick={onClick}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

MobileMenuItem.propTypes = {
  item: navigationItemShape.isRequired,
  level: PropTypes.number.isRequired,
  // Events
  onClick: PropTypes.func,
};
