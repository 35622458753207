import isNil from 'lodash/isNil';

/**
 * Content element hook used to extract & validate data
 * @param {Data for the content block} data
 * @param {Data schema for validation} schema
 * @param {(Optional) Options for the hook} options
 */
export default function useContentElement(data, schema, options = {}) {
  // Arguyment validation
  if (isNil(data)) {
    throw new Error('Argument "data" must not be null or undefined.');
  }

  return {
    elementData: data,
  };
}
