/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Grid2,
  MenuItem,
  Pagination as MuiPagination,
  PaginationItem as MuiPaginationItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FILTER_SKELETON, FUEL_TYPE, TRANSMISSION_TYPE } from '@plugins/vehiclefleetapp';
import { FiArrowRight, FiSearch } from 'react-icons/fi';
import { gql, NetworkStatus, useLazyQuery } from '@apollo/client';
import { TypographyElement, useContentElement } from '@plugins/next-cms-core';
import React, { useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import defaults from 'lodash/defaults';
import { getCarSearchRoute } from '@lib/routing';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Slider from '@components/atoms/Slider';
import { useInView } from 'react-intersection-observer';
import Hidden from '@components/atoms/Hidden';
import isEmpty from 'lodash/isEmpty';
import Link from '../atoms/Link';
import Select from '../atoms/Select';
import Container from '../atoms/Container';
import InventoryCarListItem, { GRAPHQL_REQUIRED_PROPS } from '../vehiclefleet/InventoryCarListItem';

const PAGE_QUERY_PARAM = 'isp';

export default function InventoryCarsBlock(props) {
  const { data } = props;
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { i18n, t } = useTranslation();
  const [ref, inView] = useInView();
  const isInitialMount = useRef(true);
  const containerRef = useRef(null);
  const [swiper, setSwiper] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const sliderTake = useItemPreviewCount();

  const { elementData } = useContentElement(
    data,
    InventoryCarsBlock.dataSchema,
  );

  const {
    includeDummyCars,
    branches,
    displayVariant,
    defaultItemsPerPage,
    inventoryCarFilters: filters,
    inventoryCarFilterGroups: filterGroups,
  } = elementData;
  const isSlider = isMobile || displayVariant === null || displayVariant === 'slider';
  let queryFilters = getFilterParams(router.query, defaultItemsPerPage, includeDummyCars);

  const title = elementData.title ? omitBy(elementData.title, isNull) : null;

  if (title) {
    defaults(title, {
      textAlign: 'center',
      semanticVariant: 'h3',
      displayVariant: 'h3',
    });
  }

  // Additional data
  const [fetchFilters, {
    error: filterQueryError,
    data: filterData,
  }] = useLazyQuery(QUERY_CAR_SEARCH_FILTER_DATA, {
    variables: {},
  });

  const queryCarFilters = filterGroups.length > 0
    ? filterGroups.map((group) => ({
      filters: group.filters.map((filter) => ({
        condition: filter.condition,
        type: filter.type,
        value: filter.value,
      })),
    }))
    : [
      {
        filters: filters.map((filter) => ({
          condition: filter.condition,
          type: filter.type,
          value: filter.value,
        })),
      },
    ];

  const [fetchData, {
    error: additionalDataQueryError,
    data: additionalData,
    loading,
    networkStatus,
  }] = useLazyQuery(FETCH_ADDITIONAL_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        skip: queryFilters.skip,
        take: isSlider ? 30 : queryFilters.take,
        sort: queryFilters.sort,
        branches: branches?.data?.map((x) => parseInt(x.id, 10)) ?? [],
        filterGroups: queryCarFilters,
        options: {
          dummy: queryFilters.dummy,
          fuelType: queryFilters.fuelType || null,
          transmission: queryFilters.transmission || null,
        },
      },
    },
    onCompleted: () => {
      const initialSlide = getInitialSlide();

      if (initialSlide > 0) {
        swiper.slideTo(initialSlide);
      }
    },
  });

  const slideIndex = parseInt(router.query[PAGE_QUERY_PARAM] ?? 0, 10);

  useEffect(() => {
    if (inView && isInitialMount.current) {
      fetchFilters();
      fetchData();
      isInitialMount.current = false;
    }
  }, [inView, fetchFilters, fetchData]);

  useEffect(() => {
    if (!swiper || !additionalData || swiper?.destroyed) return;
    swiper.slideTo(slideIndex);
  }, [slideIndex, swiper, additionalData]);

  if (filterQueryError || additionalDataQueryError) {
    throw filterQueryError ?? additionalDataQueryError;
  }

  useEffect(() => {
    if (isInitialMount.current) {
      return;
    }

    if (isNil(additionalData?.filteredCarsData.totalCount)) {
      return;
    }

    setTotalCount(additionalData?.filteredCarsData.totalCount);
  }, [additionalData?.filteredCarsData.totalCount]);

  const carListId = 'car-list';

  const isLoading = loading || networkStatus < NetworkStatus.ready;
  const items = additionalData?.filteredCarsData.items ?? [];
  queryFilters = { ...queryFilters };
  const isPurchasingEnabled = Boolean(additionalData?.carSearchConfig?.isPurchasingEnabled);

  const handleSetFilterConfiguration = (options = {}) => {
    const query = defaults(options, queryFilters);
    // eslint-disable-next-line no-underscore-dangle
    delete query.__typename;
    setFilterParams(query, router, containerRef);
  };

  const handleGoToPage = (event, page) => {
    event.preventDefault();
    event.stopPropagation();

    const skip = (Math.max(page - 1, 0)) * (defaultItemsPerPage ?? DEFAULT_ITEMS_PAGE_SIZE);
    const take = (defaultItemsPerPage ?? DEFAULT_ITEMS_PAGE_SIZE);

    handleSetFilterConfiguration({
      skip,
      take,
    });
  };

  const handleChangeSort = (event) => {
    const sort = event.target.value;
    handleSetFilterConfiguration({
      sort,
    });
  };

  const handleChangeFuelType = (event) => {
    const fuelType = event.target.value;
    handleSetFilterConfiguration({
      fuelType,
    });
  };

  const handleChangeTransmission = (event) => {
    const transmission = event.target.value;
    handleSetFilterConfiguration({
      transmission,
    });
  };

  const getPagingUrl = (page) => {
    const skip = (Math.max(page - 1, 0)) * DEFAULT_ITEMS_PAGE_SIZE;
    const take = DEFAULT_ITEMS_PAGE_SIZE;
    const query = {
      ...queryFilters,
      skip,
      take,
    };
    // eslint-disable-next-line no-underscore-dangle
    delete query.__typename;

    return getUrl(query);
  };

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
      }}
    >
      <Container>
        {title && (
          <Box
            sx={{
              paddingBottom: theme.spacing(5),
            }}
          >
            <TypographyElement align="center" data={title} />
          </Box>
        )}
        <Box ref={containerRef}>
          <Box mb={3}>
            <FilterBar
              data={filterData?.carSearchFilterData}
              fuelType={queryFilters.fuelType}
              i18n={i18n}
              onChangeFuelType={handleChangeFuelType}
              onChangeSort={handleChangeSort}
              onChangeTransmission={handleChangeTransmission}
              sort={queryFilters.sort}
              t={t}
              transmission={queryFilters.transmission}
            />
          </Box>
          {isSlider ? (
            <Box ref={ref} sx={{ position: 'relative' }}>
              {totalCount === 0 && (
                <EmptyResultSet t={t} />
              )}
              {isLoading && (
                <LoadingState count={sliderTake} />
              )}
              <Slider
                count={sliderTake}
                items={items}
                pageQueryParam={PAGE_QUERY_PARAM}
                setSwiper={setSwiper}
                SlideComponent={InventoryCarListItem}
                slideComponentProps={{
                  isPurchasingEnabled,
                }}
                sliderStyles={{
                  padding: 7,
                  margin: -7,
                  paddingTop: 20,
                }}
                swiper={swiper}
              />
            </Box>
          ) : (
            <Box ref={ref}>
              <Items
                id={carListId}
                isPurchasingEnabled={isPurchasingEnabled}
                items={items}
                loading={isLoading}
                t={t}
              />
              <Pagination
                getPagingUrl={getPagingUrl}
                onChangePaging={handleGoToPage}
                onlyPagination
                skip={queryFilters.skip}
                take={queryFilters.take}
                totalCount={totalCount}
              />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

function EmptyResultSet({ t }) {
  const theme = useTheme();
  return (
    <Box alignItems="center" display="flex" justifyContent="center" pb={10} pt={5}>
      <Box mr={1}>
        <FiSearch
          style={{
            color: theme.palette.text.primary,
            opacity: 0.75,
          }}
        />
      </Box>
      <Typography
        sx={{
          color: theme.palette.text.primary,
          opacity: 0.75,
        }}
      >
        {t('components.contentTypes.InventoryCarsBlock.emptyResultSet')}
      </Typography>
    </Box>
  );
}

function LoadingState({ count }) {
  return (
    <Grid2
      container
      spacing={5}
      sx={{
        alignItems: 'stretch',
      }}
    >
      {Array.from(Array(count)
        .keys())
        .map((_, index) => (
          <Grid2
            key={index}
            size={{ xs: (12 / count) }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <InventoryCarListItem useSkeleton />
          </Grid2>
        ))}
    </Grid2>
  );
}

function FilterBar(props) {
  const {
    t,
    i18n,
    data,
    sort,
    fuelType,
    transmission,
    onChangeSort,
    onChangeFuelType,
    onChangeTransmission,
  } = props;
  const columnConfig = {
    xs: 12,
    sm: 6,
    md: 3,
    lg: 3,
  };

  const {
    fuelTypes = [],
    transmissions = [],
  } = data ?? {};

  return (
    <Grid2 alignItems="center" container spacing={3}>
      <Grid2 size={columnConfig}>
        <Select
          label={t('components.contentTypes.InventoryCarsBlock.sorting')}
          onChange={onChangeSort}
          size="small"
          value={sort}
          variant="outlined"
        >
          {SORT_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option[`label_${i18n.language}`]}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2 size={columnConfig}>
        <Select
          label={t('components.contentTypes.InventoryCarsBlock.fuel')}
          onChange={onChangeFuelType}
          size="small"
          value={fuelType ?? ''}
          variant="outlined"
        >
          <MenuItem value="">
            {t('components.contentTypes.InventoryCarsBlock.allFuels')}
          </MenuItem>
          {fuelTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {FUEL_TYPE[option]}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2 size={columnConfig}>
        <Select
          label={t('components.contentTypes.InventoryCarsBlock.transmission')}
          onChange={onChangeTransmission}
          size="small"
          value={transmission ?? ''}
          variant="outlined"
        >
          <MenuItem value="">
            {t('components.contentTypes.InventoryCarsBlock.allTransmissions')}
          </MenuItem>
          {transmissions.map((option) => (
            <MenuItem key={option} value={option}>
              {TRANSMISSION_TYPE[option]}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2 size={columnConfig}>
        <Link href={getCarSearchRoute(i18n.language)}>
          <Button
            color="primary"
            component="a"
            endIcon={<FiArrowRight />}
            fullWidth
          >
            {t('components.contentTypes.InventoryCarsBlock.detailedSearch')}
          </Button>
        </Link>
      </Grid2>
    </Grid2>
  );
}

function Items({
  t,
  id,
  loading,
  items,
  isPurchasingEnabled,
}) {
  const theme = useTheme();
  if (loading) {
    return (
      <Box
        sx={{
          marginTop: theme.spacing(5),
          marginBottom: theme.spacing(5),
          display: 'grid',
          columnGap: `${theme.spacing(columnGap)}`,
          rowGap: `${theme.spacing(columnGap)}`,
          gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          },
          [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          },
        }}
      >
        <InventoryCarListItem useSkeleton />
        <InventoryCarListItem useSkeleton />
        <InventoryCarListItem useSkeleton />
      </Box>
    );
  }

  if (!items || items.length === 0) {
    return (
      <Box alignItems="center" display="flex" justifyContent="center" py={10}>
        <FiSearch
          style={{
            marginRight: theme.spacing(1),
            opacity: 0.5,
          }}
        />
        <Typography component="span" variant="body2">
          {t('pages.vehiclefleetapp.home.noVehiclesFound')}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      id={id}
      sx={{
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        display: 'grid',
        columnGap: `${theme.spacing(columnGap)}`,
        rowGap: `${theme.spacing(columnGap)}`,
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        },
      }}
    >
      {items.map((item) => (
        <InventoryCarListItem
          key={item.id}
          isPurchasingEnabled={isPurchasingEnabled}
          item={item}
        />
      ))}
    </Box>
  );
}

function Pagination(props) {
  const {
    hidePagination,
    skip,
    take,
    totalCount,
    getPagingUrl,
    onChangePaging,
  } = props;
  const page = Math.ceil(skip / take) + 1;
  const count = Math.ceil(totalCount / take);

  const renderPaginationItem = (item) => (
    <MuiPaginationItem
      component="a"
      href={getPagingUrl(item.page)}
      {...item}
    />
  );
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {!hidePagination
        ? (
          <Box>
            <Hidden dir="up" size="md">
              <MuiPagination
                count={count}
                onChange={onChangePaging}
                page={page}
                renderItem={renderPaginationItem}
                siblingCount={0}
              />
            </Hidden>
            <Hidden dir="down" size="sm">
              <MuiPagination
                boundaryCount={1}
                count={count}
                onChange={onChangePaging}
                page={page}
                renderItem={renderPaginationItem}
                siblingCount={1}
              />
            </Hidden>
          </Box>
        )
        : (
          <div />
        )}
    </Box>
  );
}

function useItemPreviewCount() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLg = useMediaQuery(theme.breakpoints.up('md'));

  let previewCount = 1;

  if (isLg) {
    previewCount = 3;
  } else if (isSm) {
    previewCount = 2;
  }

  return previewCount;
}

const columnGap = 7;

InventoryCarsBlock.typeName = 'ComponentContentInventoryCars'; // Strapi element type
InventoryCarsBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    displayVariant: PropTypes.string,
    inventoryCarFilters: PropTypes.arrayOf(PropTypes.shape({
      condition: PropTypes.oneOf(['Include', 'Exclude']).isRequired,
      type: PropTypes.oneOf(['Brand', 'Model', 'Search']).isRequired,
      value: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

InventoryCarsBlock.graphQlSchema = `
... on ${InventoryCarsBlock.typeName} {
  id
  includeDummyCars
  displayVariant
  defaultItemsPerPage
  title {
    ${TypographyElement.graphQlSchema}
  }
  branches {
    data {
      id
    }
  }
  inventoryCarFilters: filters {
    condition
    type
    value
  }
  inventoryCarFilterGroups: filterGroups {
    filters {
      condition
      type
      value
    }
  }
}
`;

function getInitialSlide() {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(PAGE_QUERY_PARAM)) {
    return parseInt(queryParams.get(PAGE_QUERY_PARAM) ?? 0, 10);
  }
  return 0;
}

function getFilterParams({
  skip,
  take,
  sort,
  ...query
}, defaultItemsPerPage, includeDummyCars) {
  skip = Math.max(parseInt(skip ?? 0, 10), 0);
  take = Math.max(
    parseInt(take ?? defaultItemsPerPage ?? DEFAULT_ITEMS_PAGE_SIZE, 10),
    defaultItemsPerPage ?? DEFAULT_ITEMS_PAGE_SIZE,
  );

  if (Number.isNaN(skip)) skip = 0;
  if (Number.isNaN(take)) take = defaultItemsPerPage ?? DEFAULT_ITEMS_PAGE_SIZE;

  sort = sort ?? SORT_OPTIONS[0].value;
  const params = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in FILTER_SKELETON) {
    if (isNil(query[key]) || isEmpty(query[key])) {
      continue;
    }
    switch (FILTER_SKELETON[key].type) {
      case 'Boolean':
        params[key] = query[key].toLowerCase() === 'true';
        break;
      case 'String':
      case 'ID':
        params[key] = query[key];
        break;
      case 'Int':
        {
          const value = parseInt(query[key], 10);
          if (!Number.isNaN(value)) {
            params[key] = value;
          }
        }
        break;
      case '[Int]':
        {
          const values = query[key].split(',');
          params[key] = values
            .map((value) => parseInt(value, 10))
            .filter((value) => !Number.isNaN(value));
        }
        break;
      case '[String]':
        params[key] = query[key].split(',');
        break;
      default:
        console.warn(`Query param of type '${FILTER_SKELETON[key].type}' not supported.`);
        break;
    }
  }

  // Do not show dummy ads in the car search
  params.dummy = includeDummyCars ?? false;

  return {
    skip,
    take,
    sort,
    ...params,
  };
}

function getUrl(query, router) {
  const queryParams = new URLSearchParams(omitBy(query, isNil)).toString();
  return `${router?.asPath.split('?')[0] ?? ''}${queryParams ? `?${queryParams}` : ''}`;
}

function setFilterParams(query, router, containerRef) {
  const url = getUrl(query, router);

  if (containerRef.current) {
    const elementTop = containerRef.current.getBoundingClientRect().top;
    window.scrollTo({
      top: window.scrollY + elementTop - 160,
      behavior: 'smooth',
    });
  }
  router.replace(url, undefined, { shallow: true });
}

const FETCH_ADDITIONAL_DATA = gql`
  query GetInventoryCars($input: FilteredCarsInput!) {
    filteredCarsData(input: $input) {
      items {
        ${GRAPHQL_REQUIRED_PROPS}
      }
      totalCount
      filter {
        skip
        take
        sort
        fuelType
        transmission
      }
    }
    carSearchConfig {
      data {
        attributes {
          isPurchasingEnabled
        }
      }
    }
  }
`;

export const QUERY_CAR_SEARCH_FILTER_DATA = gql`
  query GetSearchFilterData($fuelType: String, $transmission: String) {
    carSearchFilterData(fuelType: $fuelType, transmission: $transmission) {
      fuelTypes
      fuelType
      transmissions
      transmission
    }
  }
`;

const DEFAULT_ITEMS_PAGE_SIZE = 12;

const SORT_OPTIONS = [
  {
    value: 'is_immediately_available:desc,price:asc,leasing_rate:asc',
    label_de: 'Verfügbarkeit',
    label_en: 'Availability',
    label_es: 'Disponibilidad',
    label_ru: 'Доступность',
    label_tr: 'Kullanılabilirlik',
  },
  {
    value: 'price:asc,leasing_rate:asc',
    label_de: 'Preis aufsteigend',
    label_en: 'Price ascending',
    label_es: 'Precio ascendente',
    label_ru: 'Восходящая цена',
    label_tr: 'Yükselen fiyat',
  },
  {
    value: 'price:desc,leasing_rate:desc',
    label_de: 'Preis absteigend',
    label_en: 'Price descending',
    label_es: 'Precio descendente',
    label_ru: 'Цена снижается',
    label_tr: 'Azalan fiyat',
  },
];
